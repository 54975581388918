<template>
  <div class="password_reset">
    <b-container>
      <b-row class="password_reset__wrapper" align-h="center" align-v="center">
        <b-col cols="12" md="6">
          <b-form @submit="resetPassword">
            <b-form-group label="Email" label-for="email">
              <b-form-input
                id="email"
                type="email"
                v-model="email"
                required
              ></b-form-input>
            </b-form-group>

            <b-button class="main__button" type="submit" :disabled="loading"
              >Восстановить пароль</b-button
            >
          </b-form>

          <p v-if="error !== null" class="error_block">
            <span class="accent" v-html="error"></span>
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PasswordReset",
  data() {
    return {
      email: null,
      error: null,
      disabled: false,
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    async resetPassword(ev) {
      ev.preventDefault();

      try {
        this.$store.commit("SET_LOADING", true);

        this.error = null;

        await axios.get(`${this.$store.state.csrfUrl}`);
        await axios.post("forgot_password", { email: this.email });

        this.$bvToast.toast(
          "Ссылка для восстановления пароля отправлена на email: " + this.email
        );

        this.$store.commit("SET_LOADING", false);
      } catch (error) {
        this.$store.commit("SET_LOADING", false);
        this.error = "Ошибка. Такой email не найден";
      }
    },
  },
};
</script>

<style lang="scss">
.password_reset__wrapper {
  padding-top: 25vh;
}
.error_block {
  padding-top: 15px;
}
</style>
